import React, {useEffect, useState} from "react";
import {useAuth} from "react-oidc-context";
import {
    BreadcrumbGroup,
    Container,
    ContentLayout,
    Header,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import {PageLayout} from "../components/page-layout";
import {useSetOTPPin} from "../hooks/otp";
import {SetOtpPinForm} from "../components/set-otp-pin-form";
import {EnableUserManagement} from "../constants/admin";
import ShowAlerts from "../components/alerts"

const Breadcrumbs = () => {
    const breadcrumbItems = [
        {href: '#', text: 'Key Management'},
        {href: '/set-otp-pin', text: 'Set OTP PIN'},
    ];

    return (
        <BreadcrumbGroup
            items={breadcrumbItems}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
        />
    )
}

export const SetOtpPin = () => {
    const auth = useAuth();
    const [alerts, setAlerts] = useState<Alerts>({})
    const {setPin, pinError, isPinSuccess } = useSetOTPPin();
    const otpEnabled = auth.user?.profile?.otp_enabled as boolean ?? false
    const userManagementEnabled = EnableUserManagement(auth)

    useEffect(() => {
        if (isPinSuccess) {
            setAlerts(alerts => ({
                ...alerts, "successfully_registered_alert": {
                    message: "Your OTP pin was successfully registered! You can now register your Yubi OTP Key",
                    ariaLabel: "Success",
                    type: "success",
                }
            }));
        }
    }, [isPinSuccess])

    return (
        <PageLayout sideNavOpen={true} loggedInUsername={auth.user?.profile?.name} otpEnabled={otpEnabled} userManagementEnabled={userManagementEnabled}>
            <ContentLayout
                notifications={ShowAlerts(alerts)}
                breadcrumbs={Breadcrumbs()}
                headerVariant="high-contrast"
                header={<Header variant="h1">Security Pin Registration</Header>}
                defaultPadding={true}
                maxContentWidth={1040}
                disableOverlap={false}
            >
                <SpaceBetween direction="vertical" size="l">
                    <Container header={<Header variant="h2">Set your OTP PIN</Header>}>
                        <SetOtpPinForm loggedInUsername={auth.user?.profile?.preferred_username} error={pinError} onSubmit={setPin}/>
                    </Container>
                </SpaceBetween>
            </ContentLayout>
        </PageLayout>
    );
}