import React, {FormEvent, useEffect, useState} from "react";
import {Button, Form, SpaceBetween} from "@amzn/awsui-components-react/polaris";
import ShowAlerts from "../components/alerts"

export const RegisterMfaForm = ({loggedInUsername, error, onSubmit, isChromium}: RegisterMfaFormProps) => {
    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit();
    }
    const [formAlerts, setFormAlerts] = useState<Alerts>({})

    useEffect(() => {
        if (!isChromium) {
            setFormAlerts(prevAlerts => ({
                ...prevAlerts, "browser_compatability_warning": {
                    message: "There are known issues with registering FIDO2 keys in this browser. Please use Chrome for the best registration experience.",
                    ariaLabel: "Warning",
                    type: "warning",
                }
            }));
        }
    }, [isChromium]);

    return (
        <form onSubmit={handleSubmit}>
            <Form
                actions={<Button variant="primary" data-testid="register-button">Register</Button>}
                errorText={error}
                data-testid="register-mfa-form"
            >
                <SpaceBetween direction="vertical" size="m">
                    {!!Object.keys(formAlerts).length && <>{ShowAlerts(formAlerts)}</>}
                    <p data-testid="form-info">You are registering a key for <strong>{loggedInUsername}</strong>. Click the "Register" button below to begin the key registration process.</p>
                </SpaceBetween>
            </Form>
        </form>
    )
}