import {Alert, SpaceBetween} from "@amzn/awsui-components-react";
import React from "react";

export default function ShowAlerts(items: Alerts) {
    return (
        <SpaceBetween direction="vertical" size="xs">
            {Object.keys(items).map((key: string) => (
                <Alert key={key} statusIconAriaLabel={items[key].ariaLabel} type={items[key].type} data-testid={`alert-${key}`}>
                    {items[key].message}
                </Alert>
            ))}
        </SpaceBetween>
    );
}