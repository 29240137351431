import React, {useEffect, useState} from "react";
import {useAuth} from "react-oidc-context";
import {BreadcrumbGroup, ContentLayout, Header} from "@amzn/awsui-components-react/polaris";
import {PageLayout} from "../components/page-layout";
import {useOTPKeyRegister, useSetOTPPin} from "../hooks/otp";
import {useRegister} from "../hooks/webauthn";
import {EnableUserManagement} from "../constants/admin";
import ShowAlerts from "../components/alerts"
import {RegisterMfaForm} from "../components/register-mfa-form";
import {SetOtpPinForm} from "../components/set-otp-pin-form";
import {RegisterOtpKeyForm} from "../components/register-otp-key-form";
import Tabs from "@amzn/awsui-components-react/polaris/tabs";
import {Container} from "@amzn/awsui-components-react";

const Breadcrumbs = () => {
    const breadcrumbItems = [
        {href: '#', text: 'Key Management'},
        {href: '/register', text: 'Register security key'},
    ];

    return (
        <BreadcrumbGroup
            items={breadcrumbItems}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
        />
    )
}

export const RegisterKeyWizard = () => {
    const auth = useAuth();
    const [currentTabId, setCurrentTabId] = useState<string>("register-mfa");
    const [alerts, setAlerts] = useState<Alerts>({})
    const {register, error, isSuccess } = useRegister();
    const {setPin, pinError, isPinSuccess } = useSetOTPPin();
    const {registerKey, keyError, isKeySuccess } = useOTPKeyRegister();

    const otpEnabled = auth.user?.profile?.otp_enabled as boolean ?? false
    const userManagementEnabled = EnableUserManagement(auth)

    const clearAlerts = () => {
        setAlerts({})
    }

    useEffect(() => {
        if (isSuccess) {
            setAlerts(prevAlerts => ({
                ...prevAlerts, "successfully_registered_alert": {
                    message: `Your security key was successfully registered! ${otpEnabled ? "If you need to use OTP, you can now move on to the next step." : "You can now access the Kuiper network."}`,
                    ariaLabel: "Success",
                    type: "success",
                }
            }));
        }
    }, [isSuccess, otpEnabled])

    useEffect(() => {
        if (isPinSuccess) {
            setAlerts(alerts => ({
                ...alerts, "successfully_registered_alert": {
                    message: "Your OTP PIN has been successfully set! You can now move on to the next step.",
                    ariaLabel: "Success",
                    type: "success",
                }
            }));
        }
    }, [isPinSuccess])

    useEffect(() => {
        if (isKeySuccess) {
            setAlerts(alerts => ({
                ...alerts, "successfully_registered_alert": {
                    message: "Your security key was successfully registered for OTP! You can now login to Kuiper services and devices.",
                    ariaLabel: "Success",
                    type: "success",
                }
            }));
        }
    }, [isKeySuccess])

    const tabs = [
        {
            label: "Step 1: Register key",
            id: "register-mfa",
            content: (
                <RegisterMfaForm loggedInUsername={auth.user?.profile?.preferred_username} error={error} onSubmit={register} isChromium={window.chrome}/>
            )
        },
    ];

    if (otpEnabled) {
        tabs.push(
            {
                label: "Step 2: Set OTP PIN",
                id: "set-otp-pin",
                content: (
                    <SetOtpPinForm loggedInUsername={auth.user?.profile?.preferred_username} error={pinError} onSubmit={setPin}/>
                )
            },
            {
                label: "Step 3: Register OTP",
                id: "register-otp",
                content: (
                    <RegisterOtpKeyForm loggedInUsername={auth.user?.profile?.preferred_username} error={keyError} onSubmit={registerKey}/>
                )
            }
        )
    }

    return (
        <PageLayout sideNavOpen={false} loggedInUsername={auth.user?.profile?.name} otpEnabled={otpEnabled} userManagementEnabled={userManagementEnabled}>
            <ContentLayout
                notifications={ShowAlerts(alerts)}
                breadcrumbs={Breadcrumbs()}
                headerVariant="high-contrast"
                header={<Header variant="h1">Security Key Registration</Header>}
                defaultPadding={true}
                maxContentWidth={1040}
                disableOverlap={false}
            >
                <Container>
                    <Tabs
                        onChange={({ detail }) => {
                            setCurrentTabId(detail.activeTabId);
                            clearAlerts();
                        }
                        }
                        activeTabId={currentTabId}
                        tabs={tabs}
                    />
                </Container>
            </ContentLayout>
        </PageLayout>
    );
}