import React, {useEffect, useState} from "react";
import {useAuth} from "react-oidc-context";
import {BreadcrumbGroup, ContentLayout, Header, Container} from "@amzn/awsui-components-react/polaris";
import {PageLayout} from "../components/page-layout";
import {useRegister} from "../hooks/webauthn";
import {RegisterMfaForm} from "../components/register-mfa-form";
import {EnableUserManagement} from "../constants/admin";
import ShowAlerts from "../components/alerts"

const Breadcrumbs = () => {
    const breadcrumbItems = [
        {href: '#', text: 'Key Management'},
        {href: '/register-mfa', text: 'Register security key'},
    ];

    return (
        <BreadcrumbGroup
            items={breadcrumbItems}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
        />
    )
}

export const RegisterMFA = () => {
    const auth = useAuth();
    const [alerts, setAlerts] = useState<Alerts>({})
    const {register, error, isSuccess } = useRegister();
    const otpEnabled = auth.user?.profile?.otp_enabled as boolean ?? false
    const userManagementEnabled = EnableUserManagement(auth)

    useEffect(() => {
        if (isSuccess) {
            setAlerts(prevAlerts => ({
                ...prevAlerts, "successfully_registered_alert": {
                    message: "Your MFA token was successfully registered! You can now access the Kuiper network.",
                    ariaLabel: "Success",
                    type: "success",
                }
            }));
        }
    }, [isSuccess])

    return (
        <PageLayout sideNavOpen={true} loggedInUsername={auth.user?.profile?.name} otpEnabled={otpEnabled} userManagementEnabled={userManagementEnabled}>
            <ContentLayout
                notifications={ShowAlerts(alerts)}
                breadcrumbs={Breadcrumbs()}
                headerVariant="high-contrast"
                header={<Header variant="h1">Security Key Registration</Header>}
                defaultPadding={true}
                maxContentWidth={1040}
                disableOverlap={false}
            >
                <Container header={<Header variant="h2">Register a new security key</Header>}>
                    <RegisterMfaForm loggedInUsername={auth.user?.profile?.preferred_username} error={error} onSubmit={register} isChromium={window.chrome}/>
                </Container>
            </ContentLayout>
        </PageLayout>
    );
}